import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // implementarea curenta are update-ul de roomType actionat prin Stimulus
  // iar update-ul de interval updatat prin metoda on selected pe Picker
  // nu pot trimite date de la input interval la stimulus
  // solutia finala ar fi ca amandoua sa dea in stimulus
  // iar apoi sa facem un submit la form care sa actualizeze preturile, nu un refresh
  
  static targets = ["checkInterval", "roomTypeId"]

  hello(){
    console.log('action panel is live');
  }

  update(){
    // console.log(this.roomTypeIdTargets)
    // console.log(this.checkIntervalTarget)
    var interval = this.checkIntervalTarget.value
    // console.log(this.roomTypeIdTarget.firstChild.value)
    var roomTypeId = null

    this.roomTypeIdTargets.forEach((el, i) => {
      if(el.checked) {
        roomTypeId = el.value
      }
    });
    // var seo_url = document.getElementById('seo_url').value;
    window.location.href = location.pathname+"?interval="+(interval.split(' ').join('+'))+"&room_type_id="+roomTypeId//+"&seo_url="+seo_url
  }
}
