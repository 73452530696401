import { Controller } from "@hotwired/stimulus"
import SignaturePad from 'signature_pad';

export default class extends Controller {
  static targets = ["venit"]
  
  connect(){
    // console.log('connected');
    // console.log(this.data.get('venit'));

    if(document.getElementById('signature-pad')){
      this.signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
        backgroundColor: 'rgb(245, 245, 245)',
        penColor: 'rgb(0, 0, 0)' //penColor: 'rgb(25, 25, 112)'
      });

      this.signaturePad.addEventListener("endStroke", () => {
        console.log("Signature ended");
        document.getElementById("save").disabled = false;
      }, { once: true });
    }
  }

  clear(){
    this.signaturePad.clear();
    document.getElementById('results').innerHTML = '';
    document.getElementById("next_btn").disabled = true;
  }
  save(){
    var imageData = this.signaturePad.toDataURL('image/png');
    document.getElementById('results').innerHTML = 
				'<img src="'+imageData+'"/>';
    document.getElementById("reservation_signature").setAttribute("value", imageData);
    document.getElementById("next_btn").disabled = false;
  }

  hello(){
    console.log("hello");
  }
}
