import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["roomSelect", "rateSelect", "rateField", "bookingRateIds"];
  
  connect() {
  }

  update() {
    const selectedRoomId = this.roomSelectTarget.value;
    const selectedRoom = this.rooms.find(room => room.id == selectedRoomId);

    // Update the rates select field
    const ratesSelect = this.rateSelectTarget;
    ratesSelect.innerHTML = "";

    if (selectedRoom) {
      var bookingRateIds = '';
      selectedRoom.rates.forEach(rate => {
        const option = document.createElement("option");
        option.value = rate.id;
        option.text = rate.name;
        ratesSelect.appendChild(option);

        if (bookingRateIds){
          bookingRateIds += ',' + rate.id
        } else {
          bookingRateIds += rate.id
        }
      });

      this.bookingRateIdsTarget.value = bookingRateIds;
      this.rateFieldTarget.style.display = "block";
    } else {
      // Hide the rate field if no room is selected
      this.rateFieldTarget.style.display = "none";
    }
  }
  get rooms() {
    return JSON.parse(this.roomSelectTarget.getAttribute("rooms"));
  }
}
