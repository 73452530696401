import { Controller } from "@hotwired/stimulus"
// Charts
import Chart from 'chart.js/auto';

export default class extends Controller {
  
  static targets = ["venit","comenzi"]

  connect(){
    // console.log('connected');
    // console.log(this.data.get('venit'));
    // console.log('connected');
    // var venit = JSON.parse(this.data.get('venit'));
    // console.log(venit);
    // console.log('connected');
    // console.log(venit.x);

    if(document.getElementById('myChart')){
      var ctx = document.getElementById('myChart');
      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: JSON.parse(this.data.get('venit'))
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true
            }
          }
        }
      });
    }
  }

  hello(){
    console.log("hello");
  }
}
