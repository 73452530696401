import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ["theBillingForm"]

  // hello(){
  //   console.log('action panel is live');
  // }

  // update(){
  //   console.log(this.theBillingFormTarget);
  //   this.theBillingFormTarget.submit();
  // }
}
