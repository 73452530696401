import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["toast"]

  connect(){
    this.toastTargets.forEach((el, i) => {
      // wait 3 seconds, then fade out
      setTimeout(() => {
        el.style.transition = '2.0s';
        el.style.opacity = 0;
        el.style.display = 'none';
      }
      , 3000);
    });
  }


  hello(){
    console.log('toast is live');
  }

  close(){
    this.toastTargets.forEach((el, i) => {
      el.style.transition = '2.0s';
      el.style.opacity = 0;
      el.style.display = 'none';
    });
  }
}
