import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resolution", "roomTypeId"]

  hello(){
    console.log('chart filters is live');
  }

  update(){
    console.log(this.resolutionTarget.value)
    var url = new URL(location.href);
    url.searchParams.append('resolution', this.resolutionTarget.value);
    window.location.href = url.href
  }
}
