import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // filesTarget is going to contain the list of input elements we've added
  // files to - in other words, these are the input elements that we're going
  // to submit.
  static targets = ["files"]

  connect() {
    console.log("MultiUploadController#connect")
  }

  addFile(event) {
    // Grab some references for later
    const originalInput = event.target
    const originalParent = originalInput.parentNode

    // Create an element that contains our input element
    const selectedFile = document.createElement("div")
    selectedFile.className = "selected-file"
    selectedFile.append(originalInput)

    // Create label (the visible part of the new input element) with the name of
    // the selected file.
    var files = originalInput.files;
    for (var i = 0; i < files.length; i++) {
      var labelNode = document.createElement("label");
      var textElement = document.createTextNode(files[i].name);
      labelNode.appendChild(textElement);
      selectedFile.appendChild(labelNode);

      // var preview = document.createElement('img');
      // var reader  = new FileReader();
      // reader.onloadend = function () {
      //   preview.src = reader.result;
      // }
      // reader.readAsDataURL(files[i]);

      // selectedFile.appendChild(preview)
    }

    // Add the selected file to the list of selected files
    this.filesTarget.append(selectedFile)

    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode()

    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = ""

    // Add it to the DOM where the original input was
    originalParent.append(newInput)
  }

  removeImage(event){
    console.log('remove')
    var element = event.target.parentNode
    var op = 1;  // initial opacity
    var timer = setInterval(function () {
        if (op <= 0.1){
            clearInterval(timer)
            element.style.display = 'none'
        }
        element.style.opacity = op
        element.style.filter = 'alpha(opacity=' + op * 100 + ")"
        op -= op * 0.1
    }, 20);
    element.remove()
  }
}
