import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["amount", "link"]

  generate(e) {
    var amount = parseInt(this.amountTarget.value * 100);
    const reservation_id = parseInt(e.currentTarget.getAttribute('reservation_id'));
    const pin = parseInt(e.currentTarget.getAttribute('pin'));
    const path = e.currentTarget.getAttribute('path');

    if (isNaN(amount) || amount == 0 || isNaN(reservation_id) || isNaN(pin)) {
      return false;
    }

    const url = "https://bychoice.ro" + path + "/?a=" + amount + "&r=" + reservation_id + "&p=" + pin;
    navigator.clipboard.writeText(url);
    this.linkTarget.textContent = url;
  }
}
