import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  connect() {
    // this.element.textContent = "Hello World!"
  }
  // change(event) {


}
