import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="planner"
export default class extends Controller {
  static targets = ["column"]

  connect() {
    this.columnTargets.forEach((el, i) => {
      this.sortable = Sortable.create(el, {
        group: 'shared',
        animation: 150,
        onAdd: this.end.bind(this)
      })
    })
  }

  end(event) {
    let id = event.item.getAttribute('request_id')
    let data = new FormData()
    data.append("request[status]", event.item.parentNode.getAttribute('status'))

    fetch("/requests/"+id+"/change_status", {
      method: "PATCH", 
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: data,
    })
  }

}
