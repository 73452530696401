import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["new_feature_container"]

  addFeature(event) {
    const originalInput = event.target
    const originalFeature = originalInput.nextElementSibling

    console.log(originalInput)
    console.log(originalFeature)
    // const newFeature = originalFeature.cloneNode(true)
    

    // const newFeature = 
    
    // console.log(newFeature)

    originalFeature.append(newFeature)

    // // Create an element that contains our input element
    // const selectedFile = document.createElement("div")
    // selectedFile.className = "selected-file"
    // selectedFile.append(originalInput)

    // // Create label (the visible part of the new input element) with the name of
    // // the selected file.
    // var labelNode = document.createElement("label");
    // var textElement = document.createTextNode(originalInput.files[0].name);
    // labelNode.appendChild(textElement);
    // selectedFile.appendChild(labelNode);

    // // Add the selected file to the list of selected files
    // this.filesTarget.append(selectedFile)

    // // Create a new input field to use going forward
    // const newInput = originalInput.cloneNode()

    // // Clear the filelist - some browsers maintain the filelist when cloning,
    // // others don't
    // newInput.value = ""

    // // Add it to the DOM where the original input was
    // originalParent.append(newInput)
  }
}
