import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['city', 'address', 'map', 'coordinates'];
  marker = null;

  connect() {
    // this.initializeMap();
  }

  initializeMap() {
    let lat = 44.175915;
    let lng = 28.651936;
    if (this.coordinatesTarget.value) {
      const coordinates = this.coordinatesTarget.value.split(',');
      lat = parseFloat(coordinates[0].trim());
      lng = parseFloat(coordinates[1].trim());
    }
    const map = new google.maps.Map(this.mapTarget, {
      center: { lat: lat, lng: lng },
      zoom: 16,
    });
    // Create a marker and set its position.
    this.marker = new google.maps.Marker({
      map: map,
      position: { lat: lat, lng: lng },
      draggable: true
    });

    const input = this.addressTarget;
    const searchBox = new google.maps.places.SearchBox(input);

    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds());
    });

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      if (this.marker) {
        this.marker.setMap(null);
      }

      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry');
          return;
        }

        this.marker = new google.maps.Marker({
          map,
          title: place.name,
          position: place.geometry.location,
          draggable: true,
        });

        this.updateCoordinatesInput(this.marker.getPosition());

        bounds.extend(place.geometry.location);
      });

      map.fitBounds(bounds);
    });

    this.marker.addListener('dragend', () => {
      this.updateCoordinatesInput(this.marker.getPosition());
    });
  }

  updateCoordinatesInput(position) {
    this.coordinatesTarget.value = `${position.lat().toFixed(6)}, ${position.lng().toFixed(6)}`;
  }
}
