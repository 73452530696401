import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["blurb"]

  copy(e) {
    const blurb = this.blurbTarget.value;
    navigator.clipboard.writeText(blurb);
  }
}
