import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['form','checkbox','inputDates','rate','inputRoomTypes','room','statusOverlay','inputRoomIds']

  selectedDates = [];
  selectedStartDate = null;
  selectedEndDate = null;

  updateDates(){
    var datesString = ''; var j = 0;
    this.checkboxTargets.forEach((el, i) => {
      if(el.checked) { 
        if(j > 0) {datesString += ',';}
        datesString += el.value;
        j++;
      }
    });

    this.inputDatesTarget.value = datesString;

    if (document.getElementById('rates-form')){
      document.getElementById('rates-form').style.display = "block";
    }    
  }

  updateDates2(e){
    // console.log('updateDates2')

    this.formTarget.style.display = "block";    
    const date = e.currentTarget.dataset.date;
     
    if (!this.selectedStartDate || this.selectedEndDate) {
      this.selectedStartDate = date;
      this.selectedEndDate = null;
      this.applyStylesToStart();
    } else {
      // get 4 from date = 4_2024-01-10
      // var end_room_type_id = this.selectedStartDate.split("_")[0]; 
      this.selectedEndDate = this.selectedStartDate.split("_")[0] + '_' + date.split("_")[1];
      this.updateStyles();

      this.inputDatesTarget.value = this.selectedStartDate.split("_")[1] + ' - ' + this.selectedEndDate.split("_")[1];
      this.inputRoomTypesTarget.value = this.selectedStartDate.split("_")[0];
    }
 
  }

  applyStylesToStart() {
    this.rateTargets.forEach(cell => cell.classList.remove('selected', 'range'));
    this.rateTargets[this.dateIndex(this.selectedStartDate)].classList.add('selected');
  }

  updateStyles() {

    // Apply styles to selected dates and the range between them
    if (this.selectedStartDate && this.selectedEndDate) {
      const startIdx = this.dateIndex(this.selectedStartDate);
      const endIdx = this.dateIndex(this.selectedEndDate);

      for (let i = Math.min(startIdx, endIdx); i <= Math.max(startIdx, endIdx); i++) {
        this.rateTargets[i].classList.add('range');
      }
    }
  }

  dateIndex(date) {
    return Array.from(this.rateTargets).findIndex(cell => cell.dataset.date === date);
  }


  showNotes(e){
    // children of e div has the notes
    var notes = e.currentTarget.children[0];
    if (notes.style.display == "none"){
      notes.style.display = "block";

    } else {
      notes.style.display = "none";
    }
  }

  selectRoom(e){
    // toggle class selected
    e.currentTarget.classList.toggle('selected-status');
    this.statusOverlayTarget.style.display = "block";

    // take all roomTargets and get the selected ones
    // update inputRoomIds target with concatenated ids
    var roomIds = '';
    console.log(this.roomTargets)
    this.roomTargets.forEach((el, i) => {
      if(el.classList.contains('selected-status')) { 
        // data-room is the id
        if(roomIds != '') {roomIds += ',';}
        roomIds += el.dataset.room;
      }
    });
    this.inputRoomIdsTarget.value = roomIds;
  }
}
