import { Application } from "@hotwired/stimulus"
import Dropdown from 'stimulus-dropdown'
import Carousel from 'stimulus-carousel'
// import NestedForm from 'stimulus-rails-nested-form'

const application = Application.start()
application.register('dropdown', Dropdown)
application.register('carousel', Carousel)
/// application.register('nested-form', NestedForm)

// import '../swiper/css/bundle'
import '../../../node_modules/swiper/swiper-bundle.min.css';

// Configure Stimulus development experience.
application.debug = false
window.Stimulus   = application

export { application }
