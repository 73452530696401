import { Controller } from "@hotwired/stimulus"
import Webcam from "packs/webcam"

export default class extends Controller {
  connect(){
    Webcam.set({
      // width: 400,
			// height: 350,
			// dest_width: 800,
			// dest_height: 700,
			image_format: 'jpeg',
			jpeg_quality: 90,
      facingMode: { exact: 'environment' }
      // constraints: {} 
		});		
  }

  start(){
    Webcam.reset();
    Webcam.attach('#webcam');
  }

  clear(){
    this.signaturePad.clear();
  }

  snapshot(){
    Webcam.snap( function(data_uri) {
			// display results in page
			document.getElementById('results').innerHTML = 
				'<img src="'+data_uri+'"/>';
      document.getElementById("reservation_webcam_img").setAttribute("value", data_uri);
    });
    document.getElementById("next_btn").disabled = false;
  }

  hello(){
    console.log("hello");
  }
}
