// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { application } from "./application"

import ActionPanelController from "./actionPanel_controller.js"
application.register("actionPanel", ActionPanelController)

import BillingFormController from "./billingForm_controller.js"
application.register("billingForm", BillingFormController)

import ChartFiltersController from "./chartFilters_controller.js"
application.register("chartFilters", ChartFiltersController)

import DashboardChartController from "./dashboardChart_controller.js"
application.register("dashboardChart", DashboardChartController)

import FeatureController from "./feature_controller.js"
application.register("feature", FeatureController)

import HelloController from "./hello_controller.js"
application.register("hello", HelloController)

import ManageCalendarController from "./manageCalendar_controller.js"
application.register("manageCalendar", ManageCalendarController)

import MultiUploadController from "./multiUpload_controller.js"
application.register("multiUpload", MultiUploadController)

import SignatureController from "./signature_controller.js"
application.register("signature", SignatureController)

import ToastsController from "./toasts_controller.js"
application.register("toasts", ToastsController)

import WebcamController from "./webcam_controller.js"
application.register("webcam", WebcamController)

import CarouselController from "./carousel_controller.js"
application.register("carousel", CarouselController)

import CountryController from "./country_controller.js"
application.register("country", CountryController)

import PaymentLinkController from "./paymentLink_controller.js"
application.register("paymentLink", PaymentLinkController)

import AvansBlurbController from "./avansBlurb_controller.js"
application.register("avansBlurb", AvansBlurbController)

import AddressMapController from "./addressMap_controller.js"
application.register("addressMap", AddressMapController)

import MappingsController from "./mappings_controller.js"
application.register("mappings", MappingsController)

import RequestsController from "./requests_controller.js"
application.register("requests", RequestsController)
